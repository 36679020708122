<template>
  <div>
    <a-modal
      :visible="areShow"
      title="提交盘点单"
      cancel-text="取消"
      ok-text="确定"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="checkbox">
        <a-checkbox>打印全部分组</a-checkbox>
      </div>
      <div class="myForm">
        <div 
          v-for="(item,index) in list" :key="index"
          @click="clickTab(item,index)"
           :class="[item.show === true ? 'active' : '', 'every']"         
         >
          <div class="shan" v-if="item.show === true">
            <div class="font">选择</div>
          </div>
          {{item.name}}
        </div>
        <!-- <a-button v-for="(item,index) in list" :key="index">{{ item.name }}</a-button> -->
      </div>
    </a-modal>
  </div>
</template>
<script>
import {stocktaking} from '@/request/stock.js';
export default {
  props: ["areShow" ,'typelist',],
  data() {
    return {
      isShow: false,
      list:[],
    };
  },
  watch:{
    typelist:{
      handler(){
      const _this=this;
      this.typelist.map(item=>{
        _this.list.push({
          name:item.name,
          show:false
        })
      });
      },
      deep:true,
    }
  },
  updated(){
    // console.log(JSON.stringify(this.list    ))
  },
  methods: {
    handleCancel() {
     
    },
    handleOk(e) {
     this.$emit('changeStatue',true)
    },
    clickTab(item,index){
       item.show = !item.show;
      // console.log(JSON.stringify(this.list))
    },
   

  },
};
</script>
 <style lang="less" scoped>
/deep/.ant-modal-content {
  width: 75vh;
  background:rgba(237, 238, 242, 1);
}
/deep/.ant-btn {
  width: 17vh;
  height: 5vh;
  font-size: 1.8vh;
}
/deep/.ant-form-item-label {
  margin-left: 9vh;
}
.myForm {
    text-align: center;
   display: flex;
   
}
.checkbox {
  text-align: center;
  // padding: 5vh 0 5vh 0;
}
/deep/.ant-modal-footer button + button {
  width: 17vh;
  height: 5vh;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  color: white;
  margin-left: 5vh;
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
}
/deep/.ant-modal-footer {
  text-align: center;
  margin-top: 3vh;
}
.every{
  width:8vw;
  height:5vh;
  border:1px solid rgba(208, 208, 208, 1) ;
  border-radius: 4px;
  position: relative;
  line-height: 5vh;
  margin-left: 1vw;
  cursor: pointer;
  .shan{
    position: absolute;
    right:0vh;
    top:0vh;
    width:3vh;
    height:3vh;
    background-color:rgba(255, 102, 0, 1);
    border-radius: 3vh 0 0 0;
    transform:rotate(270deg);
      .font{  
        position: absolute;
        right:-0.3vh;
        top:-0.5vh;
        transform:rotate(140deg);
        color:white;
        font-size: 12px;
      }    
  }
}

</style>